import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GridApi, RowNode, RowSelectedEvent } from 'ag-grid-community';
import classNames from 'classnames';

import { NotificationPopup } from 'components/Popups';
import ConfirmPopup from 'components/Popups/ConfirmPopup';
import ValidationErrorPopup from 'components/Popups/ValidationErrorPopup';
import SelectionPanel, { ISelectionPanelProps } from 'components/Reusable/SelectionPanel';
import { DataGrid } from 'components/UI';

import { useDidUpdate, useIsMounted, useMemoSelector, useMount } from 'hooks';
import Api, { AssetStatus, IAddWorkItem, IAddWorkItemTag, IAsset } from 'services/Api';
import { getSongsModuleLocalAssets, setError, setSongsModule } from 'store';
import { DEFAULT_ERROR_CONFIG, getDataGridItems, getGUID, isEmpty } from 'utils';

import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';
import styles from './SongsUpload.module.scss';

import { getColumns, getRelatedGroupNodes, getTags, IEvents } from './data';
import PWPopup from './PWPopup';
import {
  FormApi,
  IRowDataState,
  ISelection,
  ISongsUploadProps,
  ISongsUploadRef,
  ItemErrors,
  SelectionType,
} from './SongsUpload.types';
import TagsForm from './TagsForm';
import { getProcessingPercentage, normalizeValueToCompare } from './utils';
import VersionsForm from './VersionsForm';
import WritersForm from './WritersForm';
import { ErrorType, IAutocompleteOption, ISongUploadItem, ISongUploadItemExt, SongVersion } from 'types';

const getProcessingIds = (items: ISongUploadItem[]): string[] => {
  return items.reduce((result: string[], item) => {
    if (typeof item.processing === 'number' && item.id) {
      result.push(item.id);
    }
    return result;
  }, []);
};

const mapAssetToSongUploadItem = (asset: IAsset): ISongUploadItem => ({
  error:
    asset.status && [AssetStatus.Error, AssetStatus.ProcessingError, AssetStatus.UploadError].includes(asset.status)
      ? 'Error'
      : undefined,
  extension: asset.title?.split('.').pop(),
  fileName: asset.fileName || undefined,
  id: asset.id,
  playbackPath: asset.audioPlaybackPath || '',
  processing: getProcessingPercentage(asset.status),
  title: asset.title?.split('.').slice(0, -1).join('.'),
  waveForm: JSON.parse(asset.waveForm || '[]'),
  labelView: true,
});

const mapToApiBody = (item: ISongUploadItem, groupAssets?: ISongUploadItem[]): IAddWorkItem => {
  const tags: IAddWorkItemTag[] = [];
  if (item.artists) item.artists.forEach((a) => tags.push({ tag: a.title, type: 'artists' }));
  if (item.genre) {
    const tag = item.genre.title;
    tags.push({ tag, type: 'genre' });
    item.subGenre && item.subGenre.forEach((s) => tags.push({ subgenre: s.title, tag, type: 'genre' }));
  }
  if (item.language) item.language.forEach((l) => tags.push({ tag: l.title, type: 'language' }));
  if (item.mood) item.mood.forEach((m) => tags.push({ tag: m.title, type: 'mood' }));
  return {
    assets: groupAssets?.map((a) => ({ assetId: a.id, version: a.version })) || [
      { assetId: item.id, version: item.version },
    ],
    bpm: item.bpm,
    tags,
    title: item.title,
    wcm: item.wcm ? Number(Number(item.wcm).toFixed(4)) : undefined,
    writers: item?.writers?.map((w) => w.id?.toString()),
    labelView: item.labelView,
  };
};

const areVersionsUnique = (nodes: RowNode[]): boolean => {
  const areCommonVersionsUnique =
    nodes
      .filter((n) => (n.data as ISongUploadItemExt).extension !== 'wav')
      .map((n) => (n.data as ISongUploadItemExt).version)
      .filter((v, i, arr) => arr.indexOf(v) !== i).length === 0;
  const areWavVersionsUnique =
    nodes
      .filter((n) => (n.data as ISongUploadItemExt).extension === 'wav')
      .map((n) => (n.data as ISongUploadItemExt).version)
      .filter((v, i, arr) => arr.indexOf(v) !== i).length === 0;
  return areCommonVersionsUnique && areWavVersionsUnique;
};

/**
 * Validates PW nodes
 * @param nodes - RowNode[]
 * @returns array of objects with errors or undefined if node is valid
 */
const validatePwNodes = (nodes: RowNode[]): (ItemErrors<ISongUploadItem> | undefined)[] => {
  const requiredFields: (keyof ISongUploadItem)[] = ['version'];
  return nodes.map((node, _, arr) => {
    const data = node.data as ISongUploadItem;
    const errors = requiredFields.reduce((err: ItemErrors<ISongUploadItem>, f) => {
      if (isEmpty(data?.[f], true)) err[f] = ErrorType.Required;
      return err;
    }, {});
    if (!errors.version && !areVersionsUnique(arr)) errors.version = ErrorType.NotUniqueVersion;
    const errorFields = Object.keys(errors) as (keyof ISongUploadItem)[];
    node.setData({
      ...node.data,
      state: {
        ...(node.data.state || {}),
        errors: errorFields.length > 0 ? { ...(node.data.errors || {}), ...errors } : undefined,
      },
    });
    return errorFields.length > 0 ? errors : undefined;
  });
};

/**
 * Validate s grid nodes
 * @param nodes - RowNode[]
 * @returns array of objects with errors or undefined if node is valid
 */
const validateNodes = (nodes: RowNode[]): (ItemErrors<ISongUploadItem> | undefined)[] => {
  const requiredFields: (keyof ISongUploadItem)[] = ['title', 'version', 'writers'];
  return nodes.map((node, _, arr) => {
    const data = node.data as ISongUploadItem;
    const errors = requiredFields.reduce((err: ItemErrors<ISongUploadItem>, f) => {
      if (isEmpty(data?.[f], true)) {
        err[f] = ErrorType.Required;
      }
      return err;
    }, {});
    if (data.groupId) {
      if (data.groupId === data.id) {
        // Title group item
        delete errors.version; // Version is optional for title group item
      } else if (!errors.version) {
        // Group children
        const titleGroupNode = arr.find((n) => (n.data as ISongUploadItemExt).id === data.groupId);
        const groupNodes = titleGroupNode ? getRelatedGroupNodes(titleGroupNode, arr) : [];
        if (!areVersionsUnique(groupNodes)) errors.version = ErrorType.NotUniqueVersion;
      }
    }
    const errorFields = Object.keys(errors) as (keyof ISongUploadItem)[];
    node.setData({
      ...node.data,
      state: {
        ...(node.data.state || {}),
        errors: errorFields.length > 0 ? { ...(node.data.errors || {}), ...errors } : undefined,
      },
    });
    return errorFields.length > 0 ? errors : undefined;
  });
};

const getSelectionButtons = (
  selectedItems: ISongUploadItem[],
  {
    onAddToPW,
    onCancelEdit,
    onDelete,
    onEditTags,
    onEditWriters,
    onEditVersions,
    onGroup,
    onSave,
    onSaveTags,
    onSaveWriters,
    onSaveVersions,
    onUngroup,
  }: IEvents,
  type = SelectionType.Common
): ISelectionPanelProps<ISongUploadItem>['buttons'] => {
  const hasGroups = Boolean(selectedItems.find((i) => i.groupId));
  const hasProcessingOrErr = Boolean(selectedItems.find((i) => typeof i.processing === 'number' || i.error));
  switch (type) {
    case SelectionType.Tags:
      return [
        !hasProcessingOrErr && { onClick: onSaveTags, text: 'Save Tags' },
        { onClick: onCancelEdit, text: 'Cancel' },
      ];
    case SelectionType.Writers:
      return [
        !hasProcessingOrErr && { onClick: onSaveWriters, text: 'Save Writers' },
        { onClick: onCancelEdit, text: 'Cancel' },
      ];
    case SelectionType.Versions:
      return [
        !hasProcessingOrErr && { onClick: onSaveVersions, text: 'Save Versions' },
        { onClick: onCancelEdit, text: 'Cancel' },
      ];
    default:
      return [
        !hasProcessingOrErr && { onClick: onSave, text: 'Save' },
        hasGroups
          ? { onClick: onUngroup, text: 'Ungroup' }
          : selectedItems.length > 1 && !hasProcessingOrErr && { onClick: onGroup, text: 'Group' },
        selectedItems.length > 1 &&
          !hasProcessingOrErr && {
            menuItems: [
              { onClick: (nodes) => onEditVersions(nodes, 'multiple'), text: 'Versions' },
              { onClick: (nodes) => onEditWriters(nodes, 'multiple'), text: 'Writer(s)' },
              { onClick: (nodes) => onEditTags(nodes, 'multiple'), text: 'Tags' },
            ],
            text: 'Bulk Edit',
          },
        !hasProcessingOrErr && { onClick: (nodes, _, e) => onAddToPW(nodes, e), text: 'Add To Existing PW' },
        { onClick: onDelete, text: 'Delete' },
      ];
  }
};

const useProcessWatching = (
  processingIds: string[],
  grid: React.MutableRefObject<GridApi | null>,
  setRowDataState: React.Dispatch<React.SetStateAction<IRowDataState>>
) => {
  useEffect(() => {
    let mounted = true;
    const unmountCallback = () => {
      mounted = false;
    };
    if (processingIds.length > 0) {
      setTimeout(async () => {
        const assets = (await Api.getAssetsByIds(processingIds)) || [];
        const songs = assets.map(mapAssetToSongUploadItem).filter((s) => s.id && processingIds.indexOf(s.id) > -1);
        if (!mounted) return unmountCallback;
        grid.current?.forEachNode((node) => {
          const data = node.data as ISongUploadItem;
          if (typeof data.processing === 'number' && data.id && processingIds.indexOf(data.id) > -1) {
            const freshData = songs.find((s) => s.id === data.id);
            if (freshData && (freshData.error !== data.error || freshData.processing !== data.processing)) {
              typeof freshData.processing === 'number' && freshData.error === data.error
                ? node.setDataValue('processing', freshData.processing) // Refresh only one cell (no row blink)
                : // Note: processing is done successfully
                  node.setData(freshData); // Flash the whole row (blink is visible)
            }
          }
        });
        setRowDataState((prev) => ({
          ...prev,
          procIds: grid.current ? getProcessingIds(getDataGridItems(grid.current)) : [],
        }));
      }, 5000);
    }
    return unmountCallback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingIds]);
};

const getMergedUniqueArray = (oldData?: IAutocompleteOption[], newData?: IAutocompleteOption[]) => {
  return [...(oldData || []), ...(newData || [])].reduce((total, item) => {
    if (!total.find((elem) => elem.id === item.id)) {
      total.push(item);
    }
    return total;
  }, [] as IAutocompleteOption[]);
};

const SongsUploads = (props: ISongsUploadProps, ref: React.Ref<ISongsUploadRef>): JSX.Element => {
  const [delData, setDelData] = useState<ISongUploadItem[]>();
  const [initialRowData, setInitialRowData] = useState<ISongUploadItem[]>([]);
  const [{ loaded, procIds, rowData }, setRowDataState] = useState<IRowDataState>({ procIds: [], rowData: [] });
  const [notification, setNotification] = useState<string>();
  const [pwPopupAnchor, setPwPopupAnchor] = useState<HTMLElement>();
  const [selection, setSelection] = useState<ISelection>({ items: [], type: SelectionType.Common });
  const [validErrPopup, setValidErrPopup] = useState<ErrorType>();

  const formApi = useRef<FormApi | null>(null);
  const grid = useRef<GridApi | null>(null);

  const dispatch = useDispatch();
  const localAssets = useMemoSelector(getSongsModuleLocalAssets);
  const isMounted = useIsMounted();

  const checkHasUnsavedChanges = useCallback((): boolean => {
    const fieldsToCheck: (keyof ISongUploadItem)[] = [
      'artists',
      'bpm',
      'genre',
      'groupId',
      'labelView',
      'language',
      'mood',
      'subGenre',
      'tags',
      'title',
      'version',
      'wcm',
      'writers',
    ];
    let result = false;
    grid.current?.forEachNode((rowNode) => {
      const data: ISongUploadItem | undefined = rowNode.data;
      for (const fieldName of fieldsToCheck) {
        const currentValue = normalizeValueToCompare(data?.[fieldName]);
        const initialValue = normalizeValueToCompare(
          initialRowData.find((d) => d.id === data?.id)?.[fieldName],
          fieldName === 'labelView' ? true : undefined
        );
        if (currentValue !== initialValue) {
          result = true;
          break;
        }
      }
    });
    return result;
  }, [initialRowData]);

  const events: IEvents = {
    onAddToPW: (nodes, e) => {
      const nodeErrors = validatePwNodes(nodes);
      const isValid = nodes.length === nodeErrors.filter((n) => !n).length;
      if (isValid) {
        setPwPopupAnchor(e.currentTarget);
      } else {
        const nodeWithErrors = nodeErrors.find((n) => n) || {};
        const errorFieldName = Object.keys(nodeWithErrors)[0] as keyof ISongUploadItem | undefined;
        setValidErrPopup(errorFieldName ? nodeWithErrors[errorFieldName] : ErrorType.Required);
      }
    },
    onCancelEdit: () => setSelection((prevSelection) => ({ ...prevSelection, type: SelectionType.Common })),
    onDelete: (nodes) => setDelData(nodes.map(({ data }) => data)),
    onEditVersions: (nodes, mode) =>
      setSelection((prevSelection) => ({
        ...prevSelection,
        formItem: mode === 'single' ? nodes[0].data : undefined,
        type: SelectionType.Versions,
      })),
    onEditTags: (nodes, mode) =>
      setSelection((prevSelection) => ({
        ...prevSelection,
        formItem: mode === 'single' ? nodes[0].data : undefined,
        type: SelectionType.Tags,
      })),
    onEditWriters: (nodes, mode) =>
      setSelection((prevSelection) => ({
        ...prevSelection,
        formItem: mode === 'single' ? nodes[0].data : undefined,
        type: SelectionType.Writers,
      })),
    onGroup: (nodes) => {
      const groupId = getGUID();
      const groupIndex = nodes[0].rowIndex ?? 0;
      const group: ISongUploadItem = { groupId, id: groupId };
      nodes.forEach(({ data }: { data: ISongUploadItem }) => {
        data.artists = undefined;
        data.bpm = undefined;
        data.genre = undefined;
        data.groupId = groupId;
        data.language = undefined;
        data.mood = undefined;
        data.subGenre = undefined;
        data.tags = undefined;
        data.wcm = undefined;
        data.writers = undefined;
      });
      let items = grid.current ? getDataGridItems<ISongUploadItem>(grid.current) : [];
      const groupChildren = items.filter((d) => d.groupId === groupId);
      items.splice(groupIndex, 1, group); // Insert group item in array
      items = items.filter((d) => d.groupId !== groupId || d === group); // Remove group children from array
      const newGroupIndex = items.indexOf(group); // Find new group item index.ts
      setRowDataState((prev) => ({
        ...prev,
        rowData: [
          ...items.slice(0, newGroupIndex), // Put items before group item
          group, // Put group item
          ...groupChildren, // Put group children
          ...items.slice(newGroupIndex + 1), // Put items after group items
        ],
      }));
      grid.current?.deselectAll();
    },
    onSave: (nodes) => {
      const nodeErrors = validateNodes(nodes);
      const isValid = nodes.length === nodeErrors.filter((n) => !n).length;
      if (isValid) {
        return Promise.all(
          nodes.map((n) => {
            const data = n.data as ISongUploadItemExt;
            n.setDataValue('state', { ...(data.state || {}), saving: true });
            if (data.groupId && data.groupId !== data.id) return undefined; // Skip group children
            const groupNodes = getRelatedGroupNodes(n, nodes);
            const groupData = groupNodes.length > 0 ? groupNodes.map(({ data: d }) => d as ISongUploadItem) : undefined;
            return Api.addWork(mapToApiBody(data, groupData), { errorPopupConfig: DEFAULT_ERROR_CONFIG }).then(
              (response) => response && grid.current?.applyTransaction({ remove: [data, ...(groupData || [])] })
            );
          })
        )
          .then((results) => results.length === results.filter((r) => r).length && props.onSave?.())
          .then(() => {
            return;
          });
      } else {
        const nodeWithErrors = nodeErrors.find((n) => n) || {};
        const errorFieldName = Object.keys(nodeWithErrors)[0] as keyof ISongUploadItem | undefined;
        setValidErrPopup(errorFieldName ? nodeWithErrors[errorFieldName] : ErrorType.Required);
      }
    },
    onSaveTags: (nodes) => {
      formApi.current?.handleSubmit(({ artists, bpm, genre, language, mood, subGenre }) => {
        grid.current?.applyTransaction({
          update: nodes.map(({ data }: { data: ISongUploadItem }): ISongUploadItem => {
            data.artists = artists;
            data.bpm = bpm;
            data.subGenre =
              (data.genre?.id === genre?.id || !genre) && nodes.length > 1
                ? getMergedUniqueArray(data.subGenre, subGenre)
                : subGenre;
            data.genre = !genre && nodes.length > 1 ? data.genre : genre;
            data.language = nodes.length > 1 ? getMergedUniqueArray(data.language, language) : language;
            data.mood = nodes.length > 1 ? getMergedUniqueArray(data.mood, mood) : mood;
            data.tags = getTags(data, 'tags');
            return data;
          }),
        });
        setSelection((prevSelection) => ({ ...prevSelection, type: SelectionType.Common }));
      })();
    },
    onSaveWriters: (nodes) => {
      formApi.current?.handleSubmit(({ writers }) => {
        grid.current?.applyTransaction({
          update: nodes.map(({ data }: { data: ISongUploadItem }): ISongUploadItem => {
            data.writers = nodes.length > 1 ? getMergedUniqueArray(data.writers, writers) : writers;
            return data;
          }),
        });
        setSelection((prevSelection) => ({ ...prevSelection, type: SelectionType.Common }));
      })();
    },
    onSaveVersions: (nodes) => {
      grid.current?.applyTransaction({
        update: nodes.map(({ data }: { data: ISongUploadItem }): ISongUploadItem => {
          data.version = formApi.current?.version?.id as SongVersion;
          return data;
        }),
      });
      setSelection((prevSelection) => ({ ...prevSelection, type: SelectionType.Common }));
    },
    onUngroup: (nodes) => {
      const data = nodes.map(({ data }: { data: ISongUploadItem }) => data);
      grid.current?.applyTransaction({
        remove: data.filter((d) => d.groupId === d.id),
        update: data
          .filter((d) => d.groupId !== d.id)
          .map((d) => {
            d.groupId = undefined;
            return d;
          }),
      });
      grid.current?.refreshCells({ force: true });
    },
  };

  const selectionButtons = useMemo(
    () => getSelectionButtons(selection.items, events, selection.type),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection.items, selection.type]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => getColumns(events), []);

  // Initial load drafts
  useMount(() => {
    (async () => {
      const assets = (await Api.getMyAssets({ skip: 0, take: 200 }, { errorPopupConfig: DEFAULT_ERROR_CONFIG })) || [];
      const songs = assets.map(mapAssetToSongUploadItem);
      if (isMounted()) {
        // Create a deep copy by JSON parse/stringify to prevent mutations
        setInitialRowData(JSON.parse(JSON.stringify(songs)));
        setRowDataState((prev) => ({ ...prev, loaded: true, procIds: getProcessingIds(songs), rowData: songs }));
      }
    })();
  });

  // Put new drafts from upload popup to grid
  useDidUpdate(() => {
    if (localAssets?.length > 0) {
      const newDrafts = localAssets.filter((d) => !rowData.find(({ id }) => id === d.id)).map(mapAssetToSongUploadItem);
      grid.current?.applyTransaction({ add: newDrafts, addIndex: 0 });
      // Create a deep copy by JSON parse/stringify to prevent mutations
      setInitialRowData((prevInitialRowData) => JSON.parse(JSON.stringify([...newDrafts, ...prevInitialRowData])));
      setRowDataState((prev) => ({ ...prev, procIds: [...prev.procIds, ...getProcessingIds(newDrafts)] }));
      dispatch(setSongsModule({ localAssets: [] }));
    }
  }, [localAssets]);

  useProcessWatching(procIds, grid, setRowDataState);

  const onDeleteConfirmed = async () => {
    const toDelete = delData?.filter((d) => d.id && d.id !== d.groupId); // Exclude title group item
    if (toDelete && toDelete.length > 0) {
      await Api.bulkDeleteAssets(
        toDelete.map(({ id }) => id ?? ''),
        { errorPopupConfig: DEFAULT_ERROR_CONFIG }
      );
      grid.current?.applyTransaction({ remove: delData });
      setDelData(undefined);
      setNotification(delData?.length === 1 ? 'Song Deleted' : 'Songs Deleted');
    }
  };

  const onPwPopupSubmit = async (work: IAutocompleteOption) => {
    const items: ISongUploadItem[] = grid.current?.getSelectedNodes().map((n) => n.data) || [];
    const result = await Api.addSongToWork(
      work.id.toString(),
      items.map((i) => ({ assetId: i.id ?? '', version: i.version ?? '' })),
      {
        handler: (details?: { errorCode?: string }) => {
          const isAlreadyExistError = details?.errorCode === '4704';

          dispatch(
            setError({
              title: isAlreadyExistError
                ? `${work.title || '(no title)'} has a version you selected already.`
                : DEFAULT_ERROR_CONFIG.title,
              text: isAlreadyExistError
                ? 'Please select a different version type and try again'
                : DEFAULT_ERROR_CONFIG.text,
              isOpen: true,
            })
          );
        },
      }
    );
    if (result) {
      setPwPopupAnchor(undefined);
      grid.current?.applyTransaction({ remove: items });
      setNotification(
        items.length === 1 ? `The song was added to ${work.title}` : `${items.length} songs were added to ${work.title}`
      );
    }
  };

  const onRowSelected = (e: RowSelectedEvent) => {
    const data = e.node.data as ISongUploadItem;
    if (data.groupId && data.groupId === data.id) {
      const selected = e.node.isSelected() || false;
      grid.current?.forEachNode((n) => {
        if ((n.data as ISongUploadItem).groupId === data.groupId) {
          n.setSelected(selected);
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({ checkHasUnsavedChanges }), [checkHasUnsavedChanges]);

  return (
    <>
      <ArrowIcon
        className={classNames(styles.expandIcon, { [styles.collapsed]: !props.expanded })}
        onClick={() => props.onExpand(!props.expanded)}
      />
      <div className={classNames(styles.songsUpload, { [styles.collapsed]: !props.expanded })}>
        <SelectionPanel
          buttons={selectionButtons}
          gridRef={grid}
          selectedItems={selection.items}
          total={grid.current?.getDisplayedRowCount()}
        >
          {selection.type === SelectionType.Tags && <TagsForm item={selection.formItem} ref={formApi} />}
          {selection.type === SelectionType.Writers && <WritersForm item={selection.formItem} ref={formApi} />}
          {selection.type === SelectionType.Versions && <VersionsForm ref={formApi} />}
        </SelectionPanel>
        <DataGrid
          className={styles.dataGrid}
          columns={columns}
          datasource={{ rowData, type: 'classic' }}
          defaultColDef={{ suppressMovable: true }}
          disableColumnsConfig
          getGridHeight={() => '380px'}
          loading={!loaded}
          onRowSelected={onRowSelected}
          onSelectionChanged={(items) => setSelection((prevSelection) => ({ ...prevSelection, items }))}
          ref={grid}
          rowClassRules={{ [styles.groupedRow]: (params) => Boolean((params.data as ISongUploadItem).groupId) }}
          rowHeight={54}
          rowSelection="multiple"
          suppressRowClickSelection
        />
        <ConfirmPopup
          btnDonePendingText="Deleting..."
          btnDoneText="Delete"
          isOpen={(delData || []).length > 0}
          mainText="This action cannot be undone"
          onClickSubmit={onDeleteConfirmed}
          questionText={delData?.length === 1 ? `Delete ${delData[0].title}?` : 'Delete selected items?'}
          setIsOpen={() => setDelData(undefined)}
        />
        <NotificationPopup
          isOpen={!!notification}
          setIsOpen={() => setNotification(undefined)}
          text={notification ?? ''}
        />
        <ValidationErrorPopup
          description="Please enter values for the highlighted fields and try again."
          isOpen={validErrPopup === ErrorType.Required}
          onClose={() => setValidErrPopup(undefined)}
          title="Required Field Missing"
        />
        <ValidationErrorPopup
          description="Please select unique versions for songs in the groups and try again."
          isOpen={validErrPopup === ErrorType.NotUniqueVersion}
          onClose={() => setValidErrPopup(undefined)}
          title="Versions are not unique"
        />
        {pwPopupAnchor && (
          <PWPopup anchorEl={pwPopupAnchor} onClose={() => setPwPopupAnchor(undefined)} onSubmit={onPwPopupSubmit} />
        )}
      </div>
    </>
  );
};

export default forwardRef(SongsUploads);
